import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { countries, EmailLogStatus } from '~/utils/constans'

// Date formating
dayjs.extend(utc)
dayjs.extend(timezone)
// Set timezone to PST

export function useDateFormating(date: string | Date | undefined, format?: string) {
  if (!date)
    return ''
  return dayjs.utc(date)
    .tz('America/Los_Angeles')
    .format(format || 'DD/MM/YYYY')
}

export function useDateFormattingWithoutTz(date: string | Date | undefined, format?: string) {
  if (!date)
    return ''
  return dayjs.utc(date).format(format || 'DD/MM/YYYY')
}

export function useCheckExpired(date: string | undefined) {
  return dayjs(date).isBefore(dayjs())
}

// Check if the expiry date is <= 30 days from today
export function useCheckExpired30days(date: string | undefined) {
  return dayjs(date).diff(dayjs(), 'days') <= 30
}

export function useCheckIsOverDate(date: string | Date | undefined) {
  return dayjs(date).isAfter(dayjs())
}

export function useTimeFromNow(date: string | Date | undefined) {
  dayjs.extend(relativeTime)
  return dayjs(date).fromNow()
}

export function useIsAdmin() {
  const userStore = useUserStore()
  return userStore.loggedIn && userStore.user.role === 'admin'
}

export function useCurrencyFormating(amount: number | undefined | null) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount || 0)
}

export function usePaymentStatus(status: number | undefined) {
  switch (status) {
    case 0:
      return 'Waiting'
    case 1:
      return 'Paid'
    case 2:
      return 'Refunded'
    case 3:
      return 'Errors'
    default:
      return 'Unknown'
  }
}

export function useVoterStatus(status: number | undefined) {
  switch (status) {
    case 0:
      return 'Undecided'
    case 1:
      return 'Approved'
    case 2:
      return 'Denied'
    case 3:
      return 'Needs Review'
    default:
      return 'Not Yet Applied'
  }
}

export function useScreeningStatus(status: number | undefined) {
  switch (status) {
    case SCREENING_STATUS_CREATED:
      return 'Created'
    case SCREENING_STATUS_PUBLISHED:
      return 'Open'
    case SCREENING_STATUS_EMAILS_SENT:
      return 'Emails Sent'
    case SCREENING_STATUS_FULL:
      return 'Event Full'
    case SCREENING_STATUS_CANCELLED:
      return 'Cancelled'
    case SCREENING_STATUS_CANCEL_EMAILS_SENT:
      return 'Cancel Emails Sent'
    case SCREENING_STATUS_FINISHED:
      return 'Finished'
    case SCREENING_STATUS_WAITING_LIST:
      return 'Waiting List'
    default:
      return 'Unknown'
  }
}

export function useAttendantStatus(status: number | undefined) {
  switch (status) {
    case ATTENDANT_STATUS_CREATED:
      return {
        label: 'Created',
        variant: 'secondary',
      }
    case ATTENDANT_STATUS_INVITED:
      return {
        label: 'Invited',
        variant: 'secondary',
      }
    case ATTENDANT_STATUS_DECLINED:
      return {
        label: 'Declined',
        variant: 'danger',
      }
    case ATTENDANT_STATUS_ATTENDING:
      return {
        label: 'Attending',
        variant: 'success',
      }
    case ATTENDANT_STATUS_CANCELLED:
      return {
        label: 'Cancelled',
        variant: 'danger',
      }
    case ATTENDANT_STATUS_ATTENDED:
      return {
        label: 'Attended',
        variant: 'success',
      }
    case ATTENDANT_STATUS_FAILED_TO_ATTEND:
      return {
        label: 'Failed to Attend',
        variant: 'danger',
      }
    case ATTENDANT_STATUS_APPEALED:
      return {
        label: 'Appealed',
        variant: 'warning',
      }
    case ATTENDANT_STATUS_APPEAL_ACCEPTED:
      return {
        label: 'Appeal Accepted',
        variant: 'success',
      }
    case ATTENDANT_STATUS_APPEAL_REJECTED:
      return {
        label: 'Appeal Rejected',
        variant: 'danger',
      }
    case ATTENDANT_STATUS_IN_WAITING_LIST:
      return {
        label: 'In Waiting List',
        variant: 'warning',
      }
    default:
      return {
        label: 'Unknown',
        variant: 'secondary',
      }
  }
}

export function useOridinalSuffix(num: number | undefined) {
  if (!num)
    return ''
  const j = num % 10
  const k = num % 100
  if (j === 1 && k !== 11)
    return `${num}st`

  if (j === 2 && k !== 12)
    return `${num}nd`

  if (j === 3 && k !== 13)
    return `${num}rd`

  return `${num}th`
}

export function useObjectToArray(obj: any) {
  return Object.entries(obj).map(([value, label]) => ({ value, label }))
}

export function useEmailLogStatus(status: number | undefined) {
  switch (status) {
    case EmailLogStatus.STATUS_NOT_SENT:
      return 'Not Sent'
    case EmailLogStatus.STATUS_SENT:
      return 'Sent'
    case EmailLogStatus.STATUS_ERRORS:
      return 'Errors'
    case EmailLogStatus.STATUS_RESEND:
      return 'Resend'
    case EmailLogStatus.STATUS_DELETED:
      return 'Deleted'
    default:
      return 'Unknown'
  }
}

export function useMailchimpLogStatus(status: number | undefined) {
  switch (status) {
    case MailchimpLogStatus.STATUS_NOT_SYNCED:
      return 'Not Synced'
    case MailchimpLogStatus.STATUS_SYNCED:
      return 'Sent'
    case MailchimpLogStatus.STATUS_RETRY:
      return 'Retry'
    case MailchimpLogStatus.STATUS_FAILED:
      return 'Failed'
    default:
      return 'Unknown'
  }
}

export function usePaymentLogStatus(status: number | undefined) {
  switch (status) {
    case PaymentLogStatus.STATUS_ERROR:
      return 'Error'
    case PaymentLogStatus.STATUS_SUCCESSFUL:
      return 'Successful'
    default:
      return 'Unknown'
  }
}

export function useGetCountryName(code: string | undefined) {
  // Get country name from country code
  return countries.find(country => country.value === code)?.label || code
}

// Number formating, 1000 => 1,000
export function useNumberFormating(num: number | undefined) {
  return new Intl.NumberFormat().format(num || 0)
}
